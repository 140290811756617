import React from 'react';
import ArticleCard from './ArticleCard.jsx';

const ArticleLists = ({ articles }) => {
  // Group articles by category
  // and ideally we want to return an array of article category cards

  const groupedArticles = articles.reduce((acc, article) => {
    const category = article.categories[0].title

    if (!acc[category]) {
      acc[category] = [];
    }

    acc[category].push(article);
    return acc;
  }, {});


  const renderArticles = (articles, category) => {
    return articles.map((article) => (
      <div className="col-lg-4" key={category}>
        <ArticleCard
          key={article._id}
          contentData={article}
          category={category}
        />
      </div>
    ))
  }

  return (
    <>
      {Object.entries(groupedArticles).map(([category, articles]) => (
        renderArticles(articles, category)
      ))}
    </>
  );
};

export default ArticleLists;