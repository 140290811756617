import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'


const Testimonials = () => {
  return (
    <Container fluid className="testimonials">
      <Row className="text-center py-5">
        <Col>
          <h1>What Taste Buds Are Saying</h1>
        </Col>
      </Row>

      <Row className="text-center justify-content-md-center">
        <Col className="pb-5" sm={{span: 5}} lg={{span: 3, offset: 0}}>
          <h3 className="pb-3"> “Light body, inviting taste, tangy flavor”</h3>
          <p><i> Tyler Phillips - Sr. Field Sales Rep <br/> San Francisco, CA </i></p>
        </Col>
        <Col className="pb-5" sm={{span: 5, offset: 1}} lg={{span: 3, offset: 1}}>
          <h3 className="pb-3"> “적절한 물의 온도와  커피의 양으로  추출된듯한  풍미있고 단 커피를  마실수 있어서  행복했다”
           <br/>
          (I was happy to have a savory, sweet coffee that was extracted with the proper water temperature and the amount of coffee.)</h3>
          <p><i> Heesun Choi - SBA Brewing Certified <br/> Seoul, South Korea </i></p>
        </Col>
      </Row>
    </Container>
  )
}

export default Testimonials
