import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

const FAQ = (props) => {
  return (
    <>
      <Container className="hero" fluid/>
      <Container className="mission section py-5" fluid>
        <Row className="justify-content-center">
          <Col sm={10} md={6}>
            <h1 className="text-center pb-3"> Frequently Asked Questions </h1>

            <div>
              <h2>What is Vietnamese Specialty Coffee?</h2>
              <p>Vietnamese Specialty coffee is coffee, from Vietnam, that is cultivated, harvested, processed, and brewed in a high-quality manner. This results unique tasting notes like: fruity, sweet, sour, blueberries, chocolatey, nutty, earthy, acidic, and etc. The technical definition of specialty coffee, by the Specialty Coffee Association, is a coffee that scores above 80 in the 100-point coffee review. </p>
            </div>

            <div>
              <h2>What does VN Beans taste like?</h2>
              <p>We have two amazing coffee beans. Our natural-processed coffee has a very unique taste. The coffee tastes fruity, sweet, light-bodied, low-acidity, blueberries and strawberries. Our semi-wash processed coffee carries the traditional smooth flavor of chocolate and mocha.</p>
            </div>

            <div>
              <h2>What is the difference between Vietnamese iced coffee (cà phê sữa đá) and VN Beans specialty coffee?</h2>
              <p>Vietnamese iced coffee is a coffee drink style, orginating from Vietnam, that contains robusta coffee and sweetened condensed milk. Unfortunately, coffee drinks called  "Vietnamese style iced coffee" is sold to consumers but the often time coffee doesn't originate from Vietnam. This leads to coffee shops benefitting from the culture of Vietnam but the coffee farmers don't.</p>
              <p>VN Beans specialty coffee is arabica coffee beans from Vietnam that is cultivated, harvested, processed, and brewed in a high-quality manner. This results in straight coffee that doesn't require cream, sure, or additives for flavor.</p>
            </div>

            <div>
              <h2>What does single-origin coffee mean?</h2>
              <p>This refers to coffee that comes a country's specific region or farm. This increases accountability because consumers can match the coffee flavor and origin to a specific place.</p>
            </div>

            <div>
              <h2>How long is shipping</h2>
              <p>2-3 days!</p>
            </div>

            <div>
              <h2>How do I brew VN Beans coffee?</h2>
              <p>We highly recommend brewing VN Beans coffee in a v60 dripper. We are working on a VN Beans brew guide, but in the meantime please refer to this <a href="https://bluebottlecoffee.com/preparation-guides/pour-over">Blue Bottle v60 Brew Guide</a>.</p>
            </div>

            <div>
              <h2>More questions?</h2>
              <p>Please feel free to contact us at the button below, we'd love to chat with you!</p>
            </div>

            <div className="text-center">
              <Button className="brand-button" href="/contact" size="lg" variant="primary">Contact Us</Button>
            </div>

          </Col>
        </Row>
      </Container>
    </>
  )
}

export default FAQ