import React from 'react';

const Parser = ({ data }) => {
  const findTitle = (title) => {
    return data.find(item => item.title === title);
  };

  const getTitle = (title) => {
    const item = findTitle(title);
    return item ? item.title : '';
  };

  const getCategories = (title) => {
    const item = findTitle(title);
    return item ? item.categories : [];
  };

  const getPublishedAt = (title) => {
    const item = findTitle(title);
    return item ? item.publishedAt : '';
  };

  const getImage = (title) => {
    const item = findTitle(title);
    return item ? item.image : '';
  };

  const getBody = (title) => {
    const item = findTitle(title);
    return item ? item.body : '';
  };

  return (
    <div>
      {/* Your JSX code here */}
    </div>
  );
};

export default Parser;