import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

const BusinessInfo = (props) => {
  return (
    <Row className="business-info">
      <Col className="pb-5" md={6}>
        <h3>{props.info.name}</h3>
        <p>
          <a target="_blank" href={props.info.google_map_link} className="address">{props.info.address}, {props.info.city} {props.info.zip_code}</a>
          <br/>
          {props.info.phone_number}
          <br/>
          <a href={props.info.website}>Website</a>
        </p>

        <h4 className="pt-5"><a>Yelp </a></h4>
        <p>{props.info.yelp_count} Reviews | {props.info.yelp_rating} Stars</p>


        <Button target="_blank" href={props.info.yelp_link} className="brand-button" >See Details</Button>
      </Col>
      <Col className="py-3" md={6}>
        <img src={props.info.location_photo_url}/>
      </Col>
    </Row>
  )
}


export default BusinessInfo