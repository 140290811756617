import React from 'react';

const TypeHandler = ({ block, markDefs }) => {
  const { _type } = block

  const renderContent = () => {
    if (_type == "block") {
      let htmlBlock  = block.children.map( (child) => {
        if (child.marks.length > 0) {
          // TODO: create span handler or child type handler
          return <TypeHandler key={child._key} block={child} markDefs={block.markDefs} />
          // if (child.marks[0] == "strong") {
          //   return (
          //     <strong key={child._key} className="text-start">
          //       <p >{child.text}</p>
          //     </strong>
          //   )
          // }
        } else {
          return (
            <span key={child._key}>{child.text}</span>
          )
        }
      })
      return <div className="mb-5">{htmlBlock}</div>

    } else if (_type == "image") {
      if (!block.asset?.url) { return }
      console.log(block)
      return (
        <div className="text-center">
          <img className="img-fluid mb-5 col-lg-5" src={block.asset.url} alt={block.alt} />
        </div>
      )
    } else if (_type == "span") {
      // TODO: create Span handler
      if (block.marks.length > 0 ) {
        if (block.marks[0] == "strong") {
          return (
            <strong key={block._key}>
              <span>{block.text}</span>
            </strong>
          )
        } else {
          // marks would be a uuid found in block.markDefs
          let def = markDefs.find( (markDef) => { return markDef._key == block.marks[0]})
          if (def._type == "link") {
            return (
              <a key={block._key} href={def.href} target="_blank" rel="noreferrer">{block.text}</a>
            )
          } else {
            return (
              <span>{block.text}</span>
            )
          }
        }
      }

    }
  }

  return (
    <>
      {renderContent()}
    </>
  )
};

export default TypeHandler;