import React from "react"
import Nav from 'react-bootstrap/Nav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'


const Footer = (props) => {
  return (

    <footer className="page-footer font-small stylish-color-dark pt-4">
      <div className="container text-center text-md-left">
        <div className="row">
          <div className="col-md-4 mx-auto">
            <img src={props.name} alt="VN Beans name logo"/>
            <p>You can't find Vietnamese Coffee anywhere. You and I -- We can change that.</p>
          </div>

          <hr className="clearfix w-100 d-md-none"/>

          <div className="col-md-2 mx-auto">
            <h5 className="font-weight-bold text-uppercase mt-3 mb-4">Navigation</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="learn">Learn</a>
              </li>
              <li>
                <a href="about">About</a>
              </li>
              <li>
                <a href="faq">FAQ</a>
              </li>
              <li>
                <a href="contact">Contact</a>
              </li>
              <li>
                <a href="https://shop.vnbeans.com/">Shop</a>
              </li>
            </ul>

          </div>
        </div>
      </div>

      <hr/>

      <ul className="list-unstyled list-inline text-center">
        <li className="list-inline-item">
          <a style={{color: "white"}} target="_blank" href="https://instagram.com/vnbeans" className="btn-floating btn-fb mx-1">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </li>
        <li className="list-inline-item">
          <a href="https://twitter.com/vn_beans" className="btn-floating btn-tw mx-1" target="_blank" >
            <FontAwesomeIcon icon={faTwitter} size="2x" color="#00ACEE"/>
          </a>
        </li>

        <li className="list-inline-item">
          <a className="btn-floating btn-li mx-1" target="_blank" >
            <FontAwesomeIcon icon={faFacebook} size="2x" color="#3B5998"/>
          </a>
        </li>
      </ul>



      <div className="footer-copyright text-center pb-4">
        <p>&copy; 2020 Copyright: Viet Nam Beans Inc.</p>
      </div>

    </footer>

  )
}

export default Footer