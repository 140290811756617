import React from "react"
import Hero from "./Hero"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

const Story = (props) => {
  return (
    <Container className="story section pb-5" fluid>
      <Row className="justify-content-center">
        <Col sm={10} md={6}>
          <h1 className="text-center pt-5 pb-3"> My Story </h1>
          <Row className="text-center py-2">
            <Col>
              <Button variant="primary" size="sm" className="text-center" role='button' active>An's Story</Button>
              &nbsp; &nbsp; &nbsp;
              <Button disabled variant="primary" size="sm" className="text-center" role='button'> Ben's Story</Button>
            </Col>
          </Row>

          <Row className="text-center py-3">
            <Col><img style={{width: "100%", borderRadius: "15px"}} src={props.an} alt="An Le, the founder standing in front of VinHomes in Saigon"/></Col>
          </Row>

          <p className="pb-3 text-center"><strong className="">
            War tore me apart from my home, coffee reconnected me
          </strong></p>

          <p>
          My family was torn away from our country because of the Vietnam War.  We arrived in America in 1991 -- I was 1. After surviving through the hell-on-earth phase of life, my immigrant parents raised me in a very traditional Vietnamese way, until I went off to college. I adopted the American culture, fulfilled my family's dream by becoming an engineer and followed their one rule -- never go back to Vietnam, there's nothing there.
          </p>

          <p>I grew up speaking Vietnamese, speaking Vietnamese, looking Vietnamese, but I had never seen my country or the people over there.</p>

          <p>I did the most American thing ever - I challenged the rule and followed my dreams.</p>

          <p>I booked a flight to Vietnam and stepped on my ancestral soil. What I saw there wasn't the pain and suffering from my parent's war-torn story, but a beautiful country full of life, opportunity, and undiscovered quality.</p>

          <p>I realized then, that I was blessed with the ability to live in 2 amazing worlds. Vietnam and America - Vietnamese-American.</p>

          <p>My adventure made me fall in love with Vietnam and my people.</p>

          <p>My vision is to bring to America the love, feels, adventure and comfort I experienced coming back to my home country. What better way to capture that, then through a wonderful familiarity in both worlds -- Coffee</p>

          <p><strong><i>The average American drinks 3.1 coffee drinks per day.
          </i></strong></p>

          <p><strong><i>Vietnam is the 2nd largest exporter of coffee in the world.
          </i></strong></p>

          <p>However, ask anyone about these facts, and they would have no idea. But you and I -- <strong>we can change that.</strong></p>

          <p>I want everyone to know about the beauty, quality, and taste of Vietnam. Let's do that together with <strong>Vietnamese Coffee.</strong></p>

          <p>
          - An Le, Founder
          </p>

        </Col>
      </Row>
    </Container>
  )
}

export default Story