import React from 'react'
import NavLink from './NavLink'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'


const NavLayout = ({logo, controllerAction}) => {

  return (
    <>
      <Navbar expand="sm" variant="light">
        <Navbar.Brand className="logo" href="/">
          <img src={logo} alt="VN Beans picture logo"/>&nbsp;
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
         <Navbar.Collapse>
          <Nav className="mr-auto text-center">
            <NavLink pageName="/home" controllerAction={controllerAction}/>
            <NavLink pageName="/about" controllerAction={controllerAction}/>
            <NavLink pageName="/articles" controllerAction={"index"} linkName="learn"/>
            {/* should fix this tobe more accurate to the controller name and action */}
            <NavLink pageName="/faq" controllerAction={controllerAction}/>
            <NavLink pageName="/contact" controllerAction={controllerAction}/>
            <NavLink pageName="/shop" url="https://shop.vnbeans.com/pages/coffee"/>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}


export default NavLayout
