import React, { useState, useEffect } from "react";
import { sanityClient } from "../Sanity/sanityClient.jsx"
import Parser from "../Sanity/Parser.jsx"
import ArticleLists from "./ArticleLists.jsx"


const Main = (props) => {
  useEffect(() => {
    getContent()
  }, [])

  const [content, setContent] = useState([])
  const [parser, setParser] = useState([])

  // Fetch content with GROQ
  const getContent = async () => {
    const CONTENT_QUERY = `*[_type == "post"] {
      ...,
      author->,
      categories[]->,
      mainImage {
        asset-> {
          url
        },
      },
      body[] {
        ...,
        children,
      }
    }
  `
    const content = await sanityClient.fetch(CONTENT_QUERY)
    setContent(content)
    setParser(Parser(content))
    return content
}

  return (
    <div className="container">
      <div className="row mb-5">
        <ArticleLists
          articles={content}
        />
      </div>
    </div>
  )
}

export default Main;




