import React, { useEffect, useState } from 'react';
import TypeHandler from '../Sanity/ContentTypes/TypeHandler.jsx'
import { sanityClient } from "../Sanity/sanityClient.jsx"

const Article = () => {

  const [content, setContent] = useState([])

  useEffect(() => {
    getArticle()
  }, [])

  const query = () => {
    const parts = window.location.pathname.split('/');
    const slug = parts[parts.length - 1];

    return `
    *[_type == "post" && slug.current == "${slug}"] {
      ...,
      author->,
      categories[]->,
      body[] {
        ...,
        asset-> {
          url
        },
        children,
      }
    }`
  }


  const getArticle = async () => {
    const content = await sanityClient.fetch(query())
    setContent(content[0])
  }

  const renderBody = () => {

    if (content.length == 0) {return}
    return content.body.map( (block) => {
      return <TypeHandler
        block={block}
        key={block._key}
      />
    })
  }

  const renderTitleAndBody = () => {
    return (
      <div className="article">
        <h1 className="mb-5">{content.title}</h1>
        {renderBody()}
      </div>
    )
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
        {content && renderTitleAndBody()}
        </div>
      </div>
    </div>
  );
};

export default Article;