import React, {useState, useEffect} from "react"
import axios from "axios"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Instagram from '../scripts/instagram-embed.js'


const InstagramFeed = () => {
  const [embeds, setEmbeds] = useState([]);

  // Empty callback is equivalent to ComponentDidMount
  useEffect(() => {
    async function fetchData() {
      const data = await axios.get("./instagram/embeds")
      await setEmbeds(data.data.embeds)
      await Instagram.call(window)
    }

    fetchData()
  }, [])

  const html = embeds.map( (embed, i) => {
    return (
      <Col md={3} key={i} >
        <div dangerouslySetInnerHTML={{ __html: embed}}/>
      </Col>
    )
  })

  return (
    <div className="instagram-feed pb-5">
      <h1 className="text-center py-5"> <a href="https://vnbeans.com">@vnbeans</a> Community</h1>
      <Row className=" justify-content-around">
        {html}
      </Row>
    </div>
  )
}

export default InstagramFeed

