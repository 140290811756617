import React from "react"
import Hero from "./Hero"
import Story from "./Story"
import Founders from "./Founders"
import Mission from "./Mission"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const About = (props) => {
  return (
    <>
      <Container className="hero" fluid/>
      <sub className="caption"> An, Farmer, Ben </sub>
      <Mission/>
      <Founders an={props.an} ben={props.ben}/>
      <Story an={props.an2}/>
    </>
  )
}

export default About