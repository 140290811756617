import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'


const WhyOurCoffee = (props) => {
  return (
    <Container fluid className="why-our-coffee py-5">
      <Row className="text-center pb-5">
        <Col>
          {/* A/B test this header */}
          {/* <h1> Why You Should Try Our Coffee </h1> */}
          <h1> Coffee the Way it Should Be </h1>
          <p>Vietnam is the 2nd biggest coffee exporter—and for good reason.</p>
        </Col>
      </Row>
      <Row className="justify-content-center pb-4">
        <Col md={3}>
          <div>
            <img src={props.props.award} alt="photo of coffee review award ranked 26 of 2021 awarded to VN Beans"/>
            <h3 className="py-2 text-center">Award-Winning Flavor</h3>
            <p><a href={props.props.coffeeReviewLink}>Rated #26 Best Coffee of 2021</a>, VN Beans has exploded onto the coffee scene by championing the <strong>only</strong> Vietnamese coffee to rank on Coffee Review.</p>            
          </div>
        </Col>
        <Col className="pb-5" md={3}>
          <img src={props.props.second} alt="photo of female smelling coffee coming from a coffee mug"/>
          <h3 className="py-2 text-center">Awakening Aroma</h3>
          <p>One cup of coffee combines the smell of nutty peanut butter, lightly roasted coffee, and a mix of fruit to give you an unforgettable aroma. </p>
        </Col>
        <Col className="pb-3" md={3}>
          <img src={props.props.third} alt="photo of vn beans coffee brewed showing red hue"/>
          <h3 className="py-2 text-center">Distinct Taste</h3>
          <p>Whether you prefer coffee that tastes fruity and sweet or the more traditional chocolate taste, you'll find your favorite with VN Beans. </p>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Button className="brand-button" href="https://shop.vnbeans.com" size="lg" variant="primary">Shop Now</Button>
        </Col>
      </Row>
    </Container>
  )
}


export default WhyOurCoffee