import React from "react"
import Hero from "./Hero"
import WhyOurCoffee from "./WhyOurCoffee"
import Products from "./Products"
import Testimonials from "./Testimonials"
import InstagramFeed from './InstagramFeed'
import PropTypes from "prop-types"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"


class Main extends React.Component {
  render () {
    return (
      <>
        <Hero url={this.props.backgroundImage}/>
        <WhyOurCoffee props={this.props}/>
        <Products product1={this.props.product1} product2={this.props.product2} award={this.props.award} awardSmall={this.props.awardSmall} coffeeReviewLink={this.props.coffeeReviewLink}/>
        <hr style={{width: "80%"}}/>
        <Testimonials/>
        <hr style={{width: "80%"}}/>
        <Row className="text-center why-us">
          <Col>
            <h1> Our Mission and Vision </h1>
          </Col>
        </Row>

        <Row className="text-center no-gutters attribute1">
          <Col className="pt-5" md>
            <Row className="justify-content-center">
              <Col sm={6} className="description pb-5">
                <h5>Introducing Real Vietnamese Coffee.</h5>
                <p className="pt-2">You probably don't think of Vietnam when thinking about where your coffee comes from, even though they're the second largest exporter of coffee in the world. We're here to change that and put Vietnamese coffee on the map—and on your kitchen counter.</p>
                <Button className="brand-button" href="https://shop.vnbeans.com" size="lg" variant="primary">Shop Now</Button>
              </Col>
            </Row>
          </Col>
          <Col md>
            <img src={this.props.pic1} alt="Showcasing the Vietnamese Specialty Coffee bloom in a kalita filter"/>
          </Col>
        </Row>

        <Row className="text-center no-gutters flex-column-reverse flex-md-row attribute2">
          <Col md>
            <img src={this.props.pic2} alt="Map of Dà Lạt, where Vietnamse Specialty Coffee is grown"/>
          </Col>
          <Col className="pt-5" md>
            <Row className="justify-content-center">
              <Col sm={6} className="description">
                <h5>Discover the Beauty of Single Origin Coffee, Directly Traded from Dalat, Vietnam</h5>
                <p>The most important thing you should know about single origin beans is that it's of higher quality and you know exactly where your coffee comes from. VN Beans are harvested in the beautiful mountains of Dalat, Vietnam, where they're known for their Arabica coffee beans. </p>
                <Button className="brand-button" href="https://shop.vnbeans.com" size="lg" variant="primary">Shop Now</Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="text-center no-gutters attribute3">
          <Col className="pt-5" md>
            <Row className="justify-content-center">
              <Col sm={6} className="description">
                <h5>A Coffee Cup for Good</h5>
                <p>You may have tried Vietnamese Iced Coffee (cà phê sữa đá), but most coffee shops don't use Vietnamese coffee beans to brew it. When you buy VN Beans, you are getting coffee beans that originate in Vietnam while championing for the hard-working farmers.</p>
                <Button className="brand-button" href="https://shop.vnbeans.com" size="lg" variant="primary">Shop Now</Button>
              </Col>
            </Row>
          </Col>
          <Col md>
            <img src={this.props.pic3} alt="A coffee farmer holding coffee cherries in her hand"/>
          </Col>
        </Row>

        <InstagramFeed/>
      </>
    );
  }
}

Main.propTypes = {
  greeting: PropTypes.string
};
export default Main
