import React from 'react'
import BusinessInfo from './BusinessInfo'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'


const Locations = (props) => {
  return (
    <>
      <Container className="hero" fluid/>
      <Container className="mission section pt-3" fluid>
        <Row className="justify-content-center">
          <Col sm={10} md={6}>
            <h1 className="text-center pb-3"> Locations </h1>
          </Col>
        </Row>
      </Container>

      <Container className="locations text-center" fluid>
        {RenderLocations(props)}
      </Container>
    </>
  )
}

const RenderLocations = (props) => {
  const cities = Object.keys(props.businesses)
  return cities.map( (city) => {
    return (
      <>
        <h1 className="py-5"> Best Coffee in {city} </h1>
        {renderAllBusinessesInCity(props.businesses[city])}
      </>
    )
  })
}

const renderAllBusinessesInCity = (businesses) => {
  return businesses.map( (biz) => {
    return <BusinessInfo info={biz}/>
  })
}

export default Locations
