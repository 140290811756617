import React, { useEffect, useState } from "react"
import Nav from 'react-bootstrap/Nav'

const NavLink = ({url, pageName='', controllerAction, linkName}) => {

  const removeSlash = () => {
    return pageName.slice(1, pageName.length)
  }
  const [normalizedPageName] = useState(removeSlash())

  const href = (url) => {
    if (url) {
      return url
    } else {
      return normalizedPageName === "home" ? "/" : ("http://" + window.location.host + "/" + normalizedPageName)
    }
  }

  const isActive = () => {
    return normalizedPageName === controllerAction ? "active" : ""
  }

  const fullClassName = (normalizedPageName) => {
    return `${normalizedPageName} ${isActive(normalizedPageName)}`
  }

  const capitalizeWord = (string) => {
    if (string == "faq") {
      return "FAQ"
    } else {
      return string[0].toUpperCase() + string.slice(1)
    }
  }

  return (
    <Nav.Link className={fullClassName(normalizedPageName)} href={href(url)}>{capitalizeWord(linkName || normalizedPageName)}</Nav.Link>
  )
}

export default NavLink