import React from "react"
import Hero from "./Hero"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Founders = (props) => {
  return (
    <Container className="founders section py-5" fluid>
      <Row className="justify-content-center">
        <Col sm={10} md={6}>
          <h1 className="text-center pb-5"> Founders </h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm={10} md={4} className="pb-5">
          <Row className="text-center">
            <Col>
              <img src={props.an}/>
              <h2 className="text-center pt-4 pb-1"> An Le </h2>
              <h6> Founder </h6>
              <p>By trade, An is a Sr. Software Engineer. Born in Saigon, Vietnam and came to the States when he was 1. Growing up with Vietnamese and American culture, there has always been a deep longing in his heart to follow his heart and return and learn about his home country.</p>
            </Col>
          </Row>
        </Col>

        <Col sm={10} md={4}>
          <Row className="text-center ">
            <Col>
              <img src={props.ben}/>
              <h2 className="text-center pt-4 pb-1"> Ben Nguyen </h2>
              <h6> Founder </h6>
              <p>Ben is a Vietnamese-American who was born in Russian, moved to the U.S. when he was nine. He recently moved back to Vietnam to understands his roots as well as to explore the tech Start-Up scene and the entrepreneurial spirits of Vietnam.</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Founders