import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

const Hero = () => {
  return (
    <Container className="hero" fluid>
    <Button className="brand-button mobile-btn" href="https://shop.vnbeans.com/" variant="primary"> Shop Now </Button>
      <Container fluid className="description text-center">
        <Row >
          <Col className="text" md={{span: 4, offset: 1}}>
            <Row>
              <h1 className="my-3">Vietnamese Specialty Coffee</h1>
            </Row>
            <Row>
              <Col className="mb-3" md={{span: 10, offset: 1}}>
                <h6>Experience single-origin arabica coffee from the mountains of Vietnam. VN Beans brings you coffee that's fruity, sweet, and not bitter—the way coffee should be.</h6>
              </Col>
            </Row>
            <Button className="brand-button desktop-btn" href="https://shop.vnbeans.com/" variant="primary"> Shop Now </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Hero