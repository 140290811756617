import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'


const Products = (props) => {
  return (
    <Container className="products">
      <Row className="text-center py-5">
        <Col>
          <h1>Treat Yourself To Better Coffee</h1>
        </Col>
      </Row>
      <Row className="text-center justify-content-md-center">
        <Col md={9}>
          <img src={props.product1} alt="Picture of Hung Farm washed process coffee bag"/>
          <h3 className="pt-2">Hung Farm</h3>
          <img className="award" src={props.awardSmall} alt="photo of coffee review award ranked 26 of 2021 awarded to VN Beans"/>
          <a href={props.coffeeReviewLink}>Rated #26 Best Coffee of 2021</a>
          <p> Taste Notes: Fruity | Strawberries | Cherries </p>
          <p>About 17 cups of coffee</p>
          <p>12 oz (340g)</p>
          <p className="mb-4 price"><strong>$24.50</strong></p>
          <Button className="brand-button" href="https://shop.vnbeans.com/" size="lg" variant="primary">Learn More</Button>
        </Col>
      </Row>
    </Container>
  )
}


export default Products