import React from 'react';

const ArticleCard = ({ contentData }) => {

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="card text-center mt-4">
            <img src={contentData.mainImage.asset.url} className="card-img-top" alt={contentData.title} />
            <div className="card-body">
              <h5 className="card-title">{contentData.title}</h5>
              <p className="card-text">{contentData.description}</p>
              <a href={`articles/${contentData.slug.current}`} className="btn btn-primary">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;