import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

const Mission = () => {
  return (
    <Container className="mission section py-5" fluid>
      <Row className="justify-content-center">
        <Col sm={10} md={4}>
          <h1 className="text-center pb-3"> Our Mission </h1>
          <p>
          We're here to bring you Vietnamese Specialty Coffee. You won't find it anywhere in your local grocery store. Even though Vietnam is:
          </p>

          <ol>
            <li>The 2nd largest exporter of coffee in the world  </li>
            <li>The 3rd largest exporter of coffee to the US </li>
          </ol>

          <p>
            With your help, we can make Specialty Arabica and Robusta coffee straight to you and your friends.
          </p>

          <p> So what are you waiting for? </p>

          <div className="text-center">
            <Button className="brand-button" href="https://shop.vnbeans.com" size="lg" variant="primary">Shop Now</Button>
          </div>

        </Col>
      </Row>
    </Container>
  )
}

export default Mission